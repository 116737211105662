@charset "utf-8";
/* TOC

- Adjustments: Video Layout
- Navigation
- Search
- Masthead
- Masthead › small-only
- Masthead › medium-only
- Masthead › large-only
- Masthead › xlarge-up
- Breadcrumb
- Meta
- Jump to top
- Footer
- Subfooter
- CSS-Classes to add margin at top or bottom

*/



/* Adjustments: Video Layout
------------------------------------------------------------------- */

body.video,
body.video #masthead-no-image-header { background: #000; }
body.video #masthead-no-image-header { margin-bottom: 60px; }
body.video h1,
body.video h2,
body.video h3,
body.video h4,
body.video h5,
body.video h6,
body.video p,
body.video a,
body.video blockquote:before,
body.video blockquote:after,
body.video cite a, { color: #fff; }
body.video cite a:visited, { color: #fff; }
body.video cite { color: #fff; }



/* Navigation
------------------------------------------------------------------- */

#navigation {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.2);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,.2);

    [class^='icon-']:before, [class*=' icon-']:before {
        margin-right: rem-calc(8);
    }
}

@media #{$medium-up} { 
  #navigation {
    border-top: 1px solid #fafafa;
  }
}


/* Search
------------------------------------------------------------------- */

.no-js form#search {
  display: none;
}



/* Masthead
------------------------------------------------------------------- */

#masthead {
    background-color: $primary-color;
}
#masthead-no-image-header {
    //JC background-color: $primary-color;
}
#masthead-with-text {
    text-align: center;
    font-size: rem-calc(54);
    font-family: $header-font-family;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 2px 3px rgba(0,0,0,.4);
}
#masthead-no-image-header {
    max-height: 110px;
    margin-top: 20px;
}

#masthead-no-image-header .row {
  border-top: 3px solid #fafafa;
  -moz-border-radius: 8px 8px 0 0;
  -webkit-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
}

#masthead-no-image-header #logo img {
    margin-top: 0px;
    -moz-border-radius: 8px 8px 0 0;
    -webkit-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
}

#mastlogo {
  padding-left: 0;
  padding-right: 0;
}

#topSpot {
  background-color: #eeeeee;
  padding: 10px 0;
}

/* Masthead › small-only
------------------------------------------------------------------- */

@media #{$small-only} {
    #logo img {
        display: none;
    }
    #masthead {
        height: 200px;
    }
    #masthead-with-pattern {
        padding: 15px 0;
    }
    #masthead-with-background-color {
        padding: 15px 0;
    }
    #masthead-with-text {
        height: 220px;
        padding: 30px 0;
        font-size: rem-calc(36);
    }
    #masthead-no-image-header {
        display: none;
    }
}


/* Masthead › medium-only
------------------------------------------------------------------- */

@media #{$medium-only} {
    #logo img {
        margin-top: 60px;
    }
    #masthead {
        height: 280px;
    }
    #masthead-with-pattern {
        padding: 20px 0;
    }
    #masthead-with-background-color {
        padding: 20px 0;
    }
    #masthead-with-text {
        padding: 60px 0;
        height: 300px;
    }
}


/* Masthead › large-only
------------------------------------------------------------------- */

@media #{$large-only} {
    #logo img {
        margin-top: 80px;
    }
    #masthead {
        height: 310px;
    }
    #masthead-with-pattern {
        padding: 30px 0;
    }
    #masthead-with-background-color {
        padding: 30px 0;
    }
    #masthead-with-text {
        height: 330px;
        padding: 60px 0;
    }
}


/* Masthead › xlarge-up
------------------------------------------------------------------- */

@media #{$xlarge-up} {
    #logo img {
        margin-top: 110px;
    }
    #masthead {
        height: 380px;
    }
    #masthead-with-pattern {
        padding: 45px 0;
    }
    #masthead-with-background-color {
        padding: 45px 0;
    }
    #masthead-with-text {
        padding: 95px 0;
        height: 400px;
    }
}


#title-image-small {
    height: 240px;
}
#title-image-large {
    height: 520px;
}
#title-image-index-small {
    height: 120px;
}
#title-image-index-large {
    height: 260px;
}



/* Breadcrumb
------------------------------------------------------------------- */

#breadcrumb {
    background: scale-color($grey-1, $lightness: 55%);
    border-top: 1px solid scale-color($grey-1, $lightness: 45%);
    border-bottom: 1px solid scale-color($grey-1, $lightness: 45%);
}
.breadcrumbs>.current {
  font-weight: bold;
}


/* Meta
------------------------------------------------------------------- */

#page-meta, #page-meta a {
    color: $grey-5;
}

#page-meta .button {
    background: $grey-5;
    border: 0;
}
#page-meta .button {
    color: #fff;
}
#page-meta .button:hover {
    background: $primary-color;
}
.meta-info p {
  font-size: rem-calc(13);
  color: scale-color($grey-1, $lightness: 40%);
}
  .meta-info a {
    text-decoration: underline;
    color: scale-color($grey-1, $lightness: 40%);
  }
  .meta-info a:hover {
    text-decoration: none;
    color: $secondary-color;
  }



/* Jump to top
------------------------------------------------------------------- */

#up-to-top {
    padding: 10px 0 10px 0;
}
#up-to-top a {
    font-size: 24px;
    padding: 5px;
    border-radius: 3px;
    color: $grey-2;
}
#up-to-top a:hover {
    background: $grey-2;
    color: #006bb3
}



/* Footer
------------------------------------------------------------------- */

#footer-content p,
#footer-content li {
    font-size: rem-calc(13);
    font-weight: 300;
}

#footer {
    padding-top: 30px;
    padding-bottom: 20px;
    background: $footer-bg;
    color: $footer-color;
    }

    #footer a {
        color: $footer-link-color;
    }
    #footer h4,
    #footer h5 {
        letter-spacing: 1px;
        color: #fff;
        text-transform: uppercase;
    }



/* Subfooter
------------------------------------------------------------------- */

#subfooter {
    background: $subfooter-bg;
    color: $subfooter-color;
    padding-top: 30px;
}

#subfooter-left ul.inline-list {
    float: left;
}

.credits a {
    color: $subfooter-link-color;
    border: 0;
    text-transform: uppercase;
    &:hover {
        color: #fff;
  }
}

.social-icons {
    margin-bottom: 10px !important;

// Beware of SCSS-Syntax here
  li {
    padding: 0 0 20px 0;
  }
  a {
    font-size: rem-calc(23);
    display: block;
    width: 36px;
    border-radius: 50%;
    color: $subfooter-bg;
    background: $subfooter-color;
    text-align: center;
    &:hover {
      background: $subfooter-bg;
      color: #fff;
    }
  }
}



/* CSS-Classes to add margin at top or bottom
------------------------------------------------------------------- */

.t0 { margin-top: 0px !important; }
.t10 { margin-top: 10px !important; }
.t15 { margin-top: 15px !important; }
.t20 { margin-top: 20px !important; }
.t30 { margin-top: 30px !important; }
.t50 { margin-top: 50px !important; }
.t60 { margin-top: 60px !important; }
.t70 { margin-top: 70px !important; }
.t80 { margin-top: 80px !important; }
.t90 { margin-top: 90px !important; }

.b0 { margin-bottom: 0px !important; }
.b5 { margin-bottom: 5px !important; }
.b10 { margin-bottom: 10px !important; }
.b15 { margin-bottom: 15px !important; }
.b20 { margin-bottom: 20px !important; }
.b30 { margin-bottom: 30px !important; }
.b60 { margin-bottom: 60px !important; }

.l15 { margin-left: 15px !important; }
.r15 { margin-right: 15px !important; }

.pl20 { padding-left: 20px !important; }
.pr5  { padding-right: 5px !important; }
.pr10 { padding-right: 10px !important; }
.pr20 { padding-right: 20px !important; }

.p15 {padding-top:15px !important; }
.p30 {padding-top:30px !important; }

/* JC extra
------------------------------------------------------------------- */



.maincontent .highfodmap li, .maincontent .lowfodmap li {
  //margin-bottom: 5px;
  //color: #555;
  //list-style-type: disc;
  //text-align: left;
 // padding-bottom: 5px;
  font-size: rem-calc(13);
  //margin-right: 0px;
 // padding-top: 0;
  //list-style-position: inside;
}

.maincontent .highfodmap ul, .maincontent .lowfodmap ul {
  margin-left: 0;
  line-height: 1.4em;
}

.maincontent .highfodmap ul ul, .maincontent .lowfodmap ul ul {
    margin-left: rem-calc(20);
}

.maincontent {
  color: #444;
  background-color: #fdfdfd;
  border-radius: 0px 0px 8px 8px;
  -webkit-box-shadow: 0 8px 6px -6px #000;
  -moz-box-shadow: 0 8px 6px -6px #000;
  box-shadow: 0 8px 6px -6px #000;
}

.highfodmap { background-color: #feebeb; padding: 10px; }
.lowfodmap {  background-color: #eefff1; padding: 10px; }

@media #{$small-only} {
  .highfodmap { margin: 0 -2%; }
  .lowfodmap { margin: 0 -2%; }
}

.highfodmap, .lowfodmap {
  font-family: Helvetica;
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.highfodmap li, .lowfodmap li {
  margin-bottom: 3px;
  padding-bottom: 0;
  line-height: 1.1rem;
  font-size: 0.75rem;
}

.highfodmap ul, .lowfodmap ul {
  padding: 0 5px 0 5px;
}

 .column {
   column-count: 2;
   column-gap: 20px;
   -moz-column-count: 2;
   -moz-column-gap: 20px;
   -webkit-column-count: 2;
   -webkit-column-gap: 20px;
 }
.highfodmap ul, .lowfodmap ul {
  list-style-position: inside;
}

#coaching {
  padding: 20px;
  border: 1px solid #bbb;
  margin: 10px 0 20px 0;
  background-color: ivory;
  text-align: center;
  line-height: 1.9em;
  color: #252525;
  font-family: Georgia, serif;
  font-size: 16px;
}

#sidebar-right {
    background-color: #efefef;
    padding-top:4px!important;
    display: flex;
    border-radius: 0 0 8px 8px;
}

#sidebar-right .recommended {
   width: 260px;
   margin: 0 auto;
}

#sidebar-right .recommended .recTitle {
  font-family: $header-font-family;
  font-weight: normal;
  padding: 0;
  text-align: center;
  margin-bottom: 6px;
}

.maincontent article {
    padding: 0 rem-calc(5) rem-calc(20) rem-calc(5);
}

@media #{$small-only} {
  .maincontent article { padding: 0 0 rem-calc(20) 0; }
}

.maincontent .updated {
    font-size: rem-calc(12);    
    color: #3f3f3f;
    margin: 4px 0 5px 0;
    font-style: italic;
    float: left;
}

#postinfo {
    float: left;
}

#postinfo .postinfoCred {
    font-size: rem-calc(11);
}

.addthis_sharing_toolbox {
    clear: none!important;
    text-align: right;
    padding: 2px 0 0 0;
    float: right;
}

.card {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 1rem;
    border: 1px solid #e6e6e6;
    border-radius: 0;
    background: #fefefe;
    box-shadow: none;
    overflow: hidden;
    color: #0a0a0a;
}

.card-flex-article {

    img {
        border-radius: 0em;
    }
  
    .avatar {
      position: relative;
  
      img {
        border-radius: 50%;
      }
  
      &.with-add-icon .fa {
        position: absolute;
        bottom: 0;
        right: 0;
        color: $alert-color;
      }
    }
  
    .card-actions {
      .fa:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  
    .card-image {
      position: relative;
    }
  
    .card-tag {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      text-transform: uppercase;
      font-weight: bold;
      border-radius: 3%;
    }
  
    .card-section {
      .article-title {
        font-family: 'Merriweather', serif;
        font-weight: bold;
        font-size: rem-calc(24);
        margin: 0;
      }
  
      .article-details {
        font-size: 0.75rem;
        opacity: 0.5;
        padding-bottom: 0.75rem;
      }
  
      .article-summary {
        font-family: 'Merriweather', serif;
      }

      p {
          margin-bottom: 0;
      }
    }
  
    .card-section {
        -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        padding: 1rem;
    }

    .user-info {
      font-size: 0.75rem;
  
      .user-name {
        font-weight: bold;
        margin-bottom: 0;
      }
    }

    .label {
        display: inline-block;
        padding: 0.33333rem 0.5rem;
        border-radius: 0;
        font-size: 0.8rem;
        line-height: 1;
        white-space: nowrap;
        cursor: default;
        background: #1779ba;
        color: #fefefe;
    }
  
    .label.alert {
        background: #dbd67a;
        color: #fefefe;
    }
  }
  
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 15px;
  }

  #recipes {
    .row {
      background-color: #f6f6f6;
      border: 1px solid #ddd;
      padding: 20px 0;
    }
    p {
      margin-bottom: 0;
    }
    img {
      margin-bottom: 0;
    }
  }


